import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { Box, Section, Text, Title } from "../../components/Core";

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const ImgStyled = styled(Box)`
  box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  overflow: hidden;
`;

const Maximo = () => {

  const data = useStaticQuery(graphql`
    query {
      containers: file(relativePath: { eq: "containers.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      {/* <!-- Content section 2 --> */}
      <Section>
        <Container>
          <Row className="align-items-center">
            <Col lg="6" sm="12" className="mb-4 mb-lg-0 order-lg-1">
              <div
                data-aos="fade-left"
                data-aos-duration="750"
                data-aos-delay="200"
                data-aos-once="true"
              >
                <ImgStyled>
                  <Img fluid={data.containers.childImageSharp.fluid} />
                </ImgStyled>
              </div>
            </Col>
            <Col lg="6" md="12" className="order-lg-0">
              <Box className="pt-5 pt-lg-0">
                <Title>
                  IBM Maximo
                </Title>
                <Text mt={4}>
                  Sharptree is an IBM Certified Silver Business Partner and Reseller. 
                </Text>
                <Text mt={4}>
                  We can provide your organization with end-to-end IBM Maximo services including software, 
                  implementation services and application support and hosting. 
                </Text>
                <Text mt={4}>
                  We are experts in all things Maximo, including planning and deploying IBM’s Maximo cloud offering, 
                  Maximo Application Suite (MAS).
                </Text>
                <Text mt={4}>
                  Do you have questions about IBM MAS?  We have the answers to your questions. 
                </Text>
              </Box>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Maximo;
