import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";

const SectionStyled = styled(Section)`
  padding-top: 0px;
`;

const FeatureCard = ({ iconName, title, children, iconColor, ...rest }) => (
  <Box px={3} {...rest}>
    <Box mb={[3, 3, 3, 3]} pb={2} fontSize="48px" color={iconColor}>
      <i className={`fa ${iconName}`} ></i>
    </Box>
    <Box>
      <Title variant="card" fontSize="24px" mb={3}>
        {title}
      </Title>
      {children}
    </Box>
  </Box>
);

const ServicesOverview = () => (
  <>
    {/* <!-- Feature section --> */}
    <SectionStyled>
      <Container>
        <Row className="justify-content-center">
          <Col lg="6" className="mb-5 mb-lg-4">
            <FeatureCard title="Consulting" iconName="fa-lightbulb" iconColor="accentYellow">
              <Text>
                Sharptree can provide consulting for your enterprise asset management technology needs.
              </Text>
              <Text mt={3}>
                Our consultants can assist your organization with system selections, EAM implementations,
                adding functionality, data management and application upgrades.
              </Text>
              <Text mt={3}>
                Our experience spans a multitude of industries including utilities, oil and gas,
                discrete manufacturing, process manufacturing (including regulated food and drug), transportation,
                Federal, state and local governments and others.
              </Text>
            </FeatureCard>
          </Col>
          <Col lg="6" className="mb-5 mb-lg-4">
            <FeatureCard title="Application Support" iconName="fa-life-ring" iconColor="accentGreen">
              <Text>
                Our Maximo and Maximo mobility experts are eager to leverage their Maximo technology and
                business process experience to support your Maximo and Mobility needs.
              </Text>
              <Text mt={3}>
                We can provide call center support, day-to-day operational support, uptime monitoring,
                data management, product fixes and improvements and application upgrades.
              </Text>
            </FeatureCard>
          </Col>
        </Row>
      </Container>
    </SectionStyled>
  </>
);

export default ServicesOverview;
