import React from "react";
import PageHero from "../components/PageHero";
import PageWrapper from "../components/PageWrapper";
import Seo from "../components/Seo";
import Maximo from "../sections/solutions/Maximo";
import Opqo from "../sections/solutions/Opqo";
import OpqoMoreInfo from "../sections/solutions/OpqoMoreInfo";
import ServicesMoreInfo from "../sections/solutions/ServicesMoreInfo";
import ServicesOverview from "../sections/solutions/ServicesOverview";
import ToolsUtilities from "../sections/solutions/ToolsUtilities";

const Solutions = (props) => {
  return (
    <>
      <PageWrapper footerDark>
        <Seo
          title="Sharptree | Solutions | Mobile Reimagined"
          description="A fresh, modern, approach to mobility for Maximo. Opqo is an easy to deploy mobile application that provides modular functionality and clear pricing."
          pathname={props.location.pathname}
        />
        <PageHero title="Solutions" />
        <Opqo />
        <OpqoMoreInfo />
        <Maximo />
        <ServicesOverview />
        <ToolsUtilities />
        <ServicesMoreInfo />
      </PageWrapper>
    </>
  );
};
export default Solutions;
