import { rgba } from "polished";
import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { A, Box, Button, Section, Title } from "../../components/Core";
import { device } from "../../utils";


const SectionStyled = styled(Section)`
  padding-bottom: 100px;
  padding-top: 100px;
`;

const OfferWrapper = styled(Box)`
  border-radius: 10px;
  background-color: ${({ theme }) => rgba(theme.colors.light, 0.05)};
  padding-top: 30px;
  padding-bottom: 30px;
  padding-right: 50px;
`;

const BtnContainer = styled(Box)`
  margin-left: 45px;
  margin-top: 30px;

  @media ${device.lg} {
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
`;

const OpqoMoreInfo = () => {

  return (
    <>
      <SectionStyled bg="primaryPurple">
        <Container>
          <OfferWrapper>
            <div className="d-flex flex-wrap">
              <div className="py-3 pl-5">
                <Title variant="quote" color="light">
                  Visit the Opqo website
                </Title>
                <Title variant="card" color="lightShade">
                  to see how easy it is to get up and running.
                </Title>
              </div>
              <BtnContainer>
                <A href="https://www.opqo.io" target="_blank" rel="noopener noreferrer">
                  <Button variant="outline">Get Started</Button>
                </A>
              </BtnContainer>
            </div>
          </OfferWrapper>
        </Container>
      </SectionStyled>
    </>
  );
};

export default OpqoMoreInfo;
