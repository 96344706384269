import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { device } from "../../utils";

import { Title, Section, Box, Text } from "../../components/Core";

import { useStaticQuery, graphql } from "gatsby"
import ToolUtilityCard from "../../components/ToolUtilityCard";

const SectionStyled = styled(Section)`
  background-color: ${({ theme }) => theme.light};
  border-bottom: 1px solid #ededf4;
  padding-bottom: 30px;
  padding-top: 30px;
  @media ${device.md} {
    padding-bottom: 70px;
  }
`;

const ToolsUtilities = () => {

  const data = useStaticQuery(graphql`
    query {

      zebra_print_utility: file(relativePath: { eq: "tools/zebra_print_utility.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      vscode_script_extension: file(relativePath: { eq: "tools/vscode_script_extension.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      autoscript_dbc: file(relativePath: { eq: "tools/autoscript_dbc.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }     
      
      lets_encrypt_ihs: file(relativePath: { eq: "tools/lets_encrypt_ihs.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }  

      excel: file(relativePath: { eq: "tools/excel.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      } 

      jobplan_to_inspection: file(relativePath: { eq: "tools/jobplan_to_inspection.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      } 

    }
  `)

  return (
    <>
      <SectionStyled>
        <Container>
          <Row className="justify-content-center">
            <Col lg="9">
              <Box className="text-center" mb={[4, 5]}>
                <Title>
                  Maximo Tools &amp; Utilities
                </Title>
                <Text>
                  These are tools and utilities we developed as part of our product development.
                  We make them available to the community, as open-source solutions, supporting our belief that
                  sharing good things only helps everyone.
                </Text>
              </Box>
            </Col>
          </Row>
          <Row className="align-items-top justify-content-left">

            <Col lg="4" className="mb-5">
              <ToolUtilityCard
                imgFluid={data.zebra_print_utility.childImageSharp.fluid}
                title="Maximo Zebra Label Printing"
                url="https://github.com/sharptree/zebra-label"
              >
                A Maximo extension that adds the ability to define Zebra labels and printers to support
                printing labels from the Item Master, Inventory and Receipts applications.
              </ToolUtilityCard>
            </Col>

            <Col lg="4" className="mb-5">
              <ToolUtilityCard
                imgFluid={data.vscode_script_extension.childImageSharp.fluid}
                title="VS Code Automation Script Extension"
                url="https://marketplace.visualstudio.com/items?itemName=sharptree.maximo-script-deploy"
                sourceUrl="https://github.com/sharptree/vscode-autoscript-deploy"
              >
                Develop, describe and deploy Maximo automation scripts directly in Visual Studio Code.
              </ToolUtilityCard>
            </Col>

            <Col lg="4" className="mb-5">
              <ToolUtilityCard
                imgFluid={data.autoscript_dbc.childImageSharp.fluid}
                title="Autoscript DBC Deployment"
                url="https://github.com/sharptree/autoscript-dbc"
              >
                Provides DBC support for deploying automation scripts that contain the configScript
                metadata used by the VS Code automation script extension.
              </ToolUtilityCard>
            </Col>

          </Row>
          <Row className="align-items-top justify-content-left">

            <Col lg="4" className="mb-5">
              <ToolUtilityCard
                imgFluid={data.lets_encrypt_ihs.childImageSharp.fluid}
                title="Let's Encrypt for Maximo"
                url="https://github.com/sharptree/letsencrypt-ihs"
              >
                Scripts for Windows and Linux that automate requesting and deploying SSL certificates
                from Let's Encrypt to your Maximo instance.
              </ToolUtilityCard>
            </Col>

            <Col lg="4" className="mb-5">
              <ToolUtilityCard
                imgFluid={data.excel.childImageSharp.fluid}
                title="Autoscript for Export to Microsoft Excel"
                url="https://github.com/sharptree/autoscript-library/tree/main/excel"
              >
                A flexible library script with functions for exporting MboSets and JDBC ResultSet objects to Microsoft Excel&reg;
                for download and/or to email the resulting Excel workbook to the user.
              </ToolUtilityCard>
            </Col>

            <Col lg="4" className="mb-5">
              <ToolUtilityCard
                imgFluid={data.jobplan_to_inspection.childImageSharp.fluid}
                title="Create Inspection Forms from Job Plans"
                url="https://github.com/sharptree/autoscript-library/tree/main/jobplan-form"
              >
                An automation script that creates a Maximo inspection form from a job plan.
                Includes an Action Launch Point, Signature Option and Toolbar menu item in
                the Job Plans application.
              </ToolUtilityCard>
            </Col>

          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default ToolsUtilities;
