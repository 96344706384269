import React from "react";
import styled from "styled-components";
import { Title, Box, Text, Span, A } from "../Core";
import Img from "gatsby-image";
import { Row } from "react-bootstrap";

const Card = styled(Box)`
  border-radius: 10px 10px;
  border: 1px solid #eae9f2;
  transition: 0.4s;
  overflow: hidden;

  &:hover {
    box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  }
`;

const ImageContainerVertical = styled(Box)`
  overflow: hidden;
  position: relative;
  width: 100%;
  min-height: 220px;
  max-height: 220px;
`;


const CardText = styled(Box)`
  padding: 30px;
`;

const TitleStyled = styled(Title)`
  transition: 0.3s;
  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const AStyled = styled(A)`
  font-size: 16px;
  font-weight: 400;
`;

const ToolUtilityCard = ({
  imgFluid,
  preTitle,
  title,
  children,
  url,
  sourceUrl,
  ...rest
}) => (
  <Card
    className=""
    {...rest}
  >
    <ImageContainerVertical>
      <A href={url} target="_blank" rel="noopener noreferrer">
        <Img fluid={imgFluid} />
      </A>
    </ImageContainerVertical>

    <CardText>
      <A href={url} target="_blank" rel="noopener noreferrer">
        <TitleStyled variant="card" mb="14px">
          {title}
        </TitleStyled>
      </A>
      <Text fontSize={2} lineHeight={1.75} mb="16px">
        {children}
      </Text>
      <Row className="justify-content-between px-3">
        <Box>
          <AStyled href={url} target="_blank" rel="noopener noreferrer">
            <Span color="primary">More Info</Span>
          </AStyled>
        </Box>

        {sourceUrl && (
          <Box className="align-items-end justify-content-right">
            <AStyled href={sourceUrl} target="_blank" rel="noopener noreferrer">
              <Span color="primary">Source</Span>
            </AStyled>
          </Box>
        )}

      </Row>
    </CardText>
  </Card >
);

export default ToolUtilityCard;
