import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { rgba } from "polished";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { Box, Section, Text, Title } from "../../components/Core";
import { device } from "../../utils";



const SectionStyled = styled(Section)`
  background-color: ${({ theme }) => theme.colors.light};
`;


const ImgStyled = styled(Box)`
  box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  border-radius: 8px;
  overflow: hidden;
`;

const ULStyled = styled.ul`
  list-style: none;
  margin: 0;
  padding-top: 15px;
  padding-left: 0;

  @media ${device.sm} {
    padding-top: 35px;
    flex-wrap: wrap;
  }
  @media ${device.lg} {
    justify-content: space-between;
  }

  li {
    &:nth-child(odd) {
      @media ${device.sm} {
        margin-right: 40px;
      }
    }
    color: #19191b;
    font-size: 21px;
    font-weight: 500;
    letter-spacing: -0.66px;
    line-height: 50px;
    display: flex;
    margin-bottom: 5px;

    &:before {
      content: "\f00c";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      display: inline-block;
      font-size: 13px;
      width: 30px;
      height: 30px;
      background-color: ${({ theme }) => rgba(theme.colors.secondary, 0.1)};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 500px;
      color: ${({ theme }) => theme.colors.secondary};
      position: relative;
      top: 9px;
      margin-right: 13px;
    }
  }
`;

const Opqo = () => {

  const data = useStaticQuery(graphql`
    query {
      tablet_detail_content: file(relativePath: { eq: "tablet_detail_content.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      opqo_logo: file(relativePath: { eq: "opqo_logo.png" }) {
        childImageSharp {
          fixed (height: 70) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <>
      {/* <!-- Content section 1 --> */}
      <SectionStyled
        className="position-relative"
        pt={["50px", null, "130px", null, "180px"]}
      >
        <Container>
          <Row className="align-items-center">
            <Col lg="6" className="order-lg-2">
              <div
                data-aos="fade-left"
                data-aos-duration="750"
                data-aos-delay="200"
                data-aos-once="true"
              >
                <ImgStyled>
                  <Img
                    fluid={data.tablet_detail_content.childImageSharp.fluid}
                  />
                </ImgStyled>
              </div>
            </Col>
            <Col lg="6" className="order-lg-1 mt-5 mt-lg-0 pr-lg-5">
              <div>
                <Row>
                  <div className="pl-2 pr-4">
                    <Img fixed={data.opqo_logo.childImageSharp.fixed} />
                  </div>
                  <Title>
                    Opqo
                  </Title>
                </Row>
                <Text mb={0}>
                  The new face of Maximo mobility.
                </Text>
                <ULStyled>
                  <li>Simple to deploy</li>
                  <li>Simple to use</li>
                  <li>Simple to configure and manage</li>
                  <li>Process driven</li>
                  <li>Connected and disconnected</li>
                </ULStyled>
                <Text mt={4}>
                  Opqo provides a simple yet effective format for getting vital information to
                  your personnel in the field.
                </Text>
                <Text mt={3}>
                  Opqo is priced per user per month.
                  This provides both predictability of costs and the flexibility to add or remove
                  (yes, we said remove) users as your needs change.
                </Text>
                <Text mt={3}>
                  All without renegotiating contracts. Just pay for what you use, it’s that simple.
                </Text>
              </div>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Opqo;
