import { Link } from "gatsby";
import { rgba } from "polished";
import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { Box, Button, Section, Title } from "../../components/Core";
import { device } from "../../utils";


const SectionStyled = styled(Section)`
  padding-bottom: 100px;
  padding-top: 100px;
`;

const OfferWrapper = styled(Box)`
  border-radius: 10px;
  background-color: ${({ theme }) => rgba(theme.colors.light, 0.05)};
  padding-top: 30px;
  padding-bottom: 30px;
  padding-right: 50px;
`;

const BtnContainer = styled(Box)`
  margin-left: 45px;
  margin-top: 30px;

  @media ${device.lg} {
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
`;

const ServicesMoreInfo = () => (
  <>
    <SectionStyled bg="primaryPurple">
      <Container>
        <OfferWrapper>
          <div className="d-flex flex-wrap">
            <div className="py-3 pl-5">
              <Title variant="quote" color="light">
                Contact us for more information
              </Title>
            </div>
            <BtnContainer>
              <Link to="/contact">
                <Button variant="outline">Contact Us</Button>
              </Link>
            </BtnContainer>
          </div>
        </OfferWrapper>
      </Container>
    </SectionStyled>
  </>
);

export default ServicesMoreInfo;
